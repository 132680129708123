<template>
  <div class="base-card-agent-testimoni">
    <div class="agent-src-circle">
      <ImageLazy
        :src="`/images/${src}`"
        :alt="title + '.png'"
        @error="(event) => setErrorImage(event, 'testimoni')"
      />
    </div>
    <div class="agent-wrapper">
      <div>
        <span class="agent-name">{{ title }}</span>
        <span>{{ name }}</span>
      </div>
      <div class="agent-comment">
        <p>{{ desc }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ImageLazy from '@/components/ImageLazy/index.vue';
import { setImageDefault } from '@/components/ImageLazy/util'

export default {
  name: "CardAgentInformation",
  components: { ImageLazy },
  props: {
    src: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: true,
    }
  },
  methods: {
    setErrorImage(event, addClass) {
      setImageDefault(event, addClass)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/style";
@import "src/assets/css/flexbox";
@import "src/assets/css/calculate";

.base-card-agent-testimoni {
  height: 200px;
  display: flex;
  flex-direction: column;
  @include cal-size(height, 300, 350);

  background-color: $color-gray-pensive;
  padding: 2% 0 0 0;
  border-radius: 16px;
  width: 263px;
}

.agent-wrapper {
  display: flex;
  flex-direction: column;

  div:nth-child(1) {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 3% 0.5% 5% 0.5%;
  }

  div:last-of-type {
    padding: 0 4%;
  }
}

.agent-src-circle {
  > div {
    width: 7em;
    margin: 0 auto;
    min-height: 100px;
    
    ::v-deep img {
      border-radius: 100%;
      object-fit: cover;
    }
  }
}

.agent-name {
  font-weight: 600;
  @include cal-size(font-size, 16, 18);
  line-height: 24px;
  color: $color-base-text;

  + span {
    color: $color-base-text;
    font-weight: 400;
  }
}

.agent-comment {
  p {
    color: $color-text-small;
    @include cal-size(font-size, 10, 14);
    line-height: 175%;
  }
}

.image-default__testimoni {
  @include image-default(180px !important, auto)
}
</style>
