<template>
  <div id="base-agent">
    <modal-agent v-if="showModal" @close="showModal = false" />
    <header id="agent-header">
      <image-lazy
        :src="getBannerAgent"
        alt="Agen Lion Parcel"
        @error="event => setErrorImage(event, 'banner')"
      />
      <bread-crumb
        id="breadcrumb-agent"
        :class="['top-left', { 'image-default__breadcrumb': isErrorImage }]"
      />
      <div :class="['header-motto', { 'image-default__header': isErrorImage }]">
        <h1 class="header-motto__title header-motto__title-desktop">
          <span>Modal Sedikit,</span>
          <span>Untung Ratusan Juta</span>
        </h1>
        <h1 class="header-motto__title header-motto__title-mobile">
          <span>Modal Sedikit, Untung</span>
          <span> Ratusan Juta</span>
        </h1>
        <h2 class="header-motto__desc">
          <span>Ayo Gabung Menjadi</span>
          <span style="font-weight: 600;">Agen Lion Parcel</span>
        </h2>
        <div>
          <the-button
            class="join-us"
            :custom-padding="true"
            text="Gabung Sekarang"
            img="login_navbar.svg"
            :type-class="isErrorImage ? 'red' : 'outline-tr'"
            @click="openModal('header')"
          />
        </div>
      </div>
    </header>
    <div class="wrapper-tab">
      <a :href="`#${anchor}`">
        <tabbing
          :current-tab="currentTab"
          :list-tab="listTab"
          @tabClick="tabClick"
        />
      </a>
    </div>
    <div>
      <agent-list :label="listTab[currentTab]" @chooseForm="chooseForm" @openModal="openModal()"/>
    </div>
    <footer v-if="currentTab === 0" id="agent-footer">
      <div id="agent-footer__card">
        <div id="agent-footer__title-lg">
          Ingin Menjadi Agen Lion Parcel Seperti Mereka?
        </div>
        <div id="agent-footer__title-md">
          Ingin Menjadi Agen Lion Parcel?
        </div>
        <div id="agent-footer__office">
          <article>
            <image-lazy
              :src="'/images/agent/agent_office_1.webp'"
              alt="Agent Lion Parcel Office.webp"
              class="agent-office-img"
              @error="event => setErrorImage(event, 'agent-office-1')"
            />
            <image-lazy
              :src="'/images/agent/agent_office_2.webp'"
              alt="Agent Lion Parcel Office.webp"
              class="agent-office-img"
              @error="event => setErrorImage(event, 'agent-office-2')"
            />
          </article>
        </div>
        <div id="agent-footer__motto">
          <span>Modal ringan dan syarat mudah. Gabung sekarang!</span>
        </div>
        <div id="agent-footer__join">
          <the-button
            text="Perorangan"
            type-class="red"
            @click="$router.push('/agen/perorangan-app')"
          />
          <the-button
            text="Perusahaan"
            type-class="outline-red"
            @click="$router.push('/agen/perusahaan-app')"
          />
        </div>
        <div id="agent-footer__question">
          informasi lainnya <a href="/faq">FAQ</a>
        </div>
      </div>
      <div id="agent-footer__img">
        <image-lazy
          :src="getFooterAgent"
          alt="jadi agen Lion Parcel.png"
          @error="event => setErrorImage(event, 'footer')"
        />
      </div>
    </footer>
    <div id="join-agent-btn" @click="openModal()">
      Daftar Sekarang
    </div>
  </div>
</template>

<script>
import Tabbing from '@/components/new-tab/Tabbing';
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb';
import TheButton from '@/components/new-button/Button';
import mixinMetaInfo from '@/misc/mixinMetaInfo';
import AgentList from './child/index';
import ModalAgent from './components/Modal';
import mixinMobile from '@/misc/mixinMobile';
import ImageLazy from '@/components/ImageLazy/index.vue';
import { setImageDefault } from '@/components/ImageLazy/util';

export default {
  name: 'AgentPage',
  components: {
    TheButton,
    ModalAgent,
    AgentList,
    BreadCrumb,
    Tabbing,
    ImageLazy,
  },
  mixins: [mixinMetaInfo, mixinMobile],
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      listTab: [
        'informasi',
        'syarat administrasi',
        'syarat operasional',
        'ketentuan umum',
      ],
      currentTab: 0,
      showModal: false,
      anchor: '',
      debounce: {
        time: 0,
        duration: 300,
      },
      searchMetaInfo: '_agen',
      isErrorImage: false,
    };
  },
  computed: {
    getBannerAgent() {
      return this.isMobileSize
        ? '/images/agent/header_mobile.webp'
        : '/images/agent/header.webp';
    },
    getFooterAgent() {
      return this.isMobileSize
        ? '/images/agent/footer_mobile.webp'
        : '/images/agent/footer.webp';
    },
  },
  watch: {
    $route: {
      handler(to) {
        const url = to.hash.replace('#', '').replace('-', ' ');
        this.currentTab = this.listTab.indexOf(url);
        if (!to.hash) {
          this.currentTab = 0;
        }
      },
      deep: true,
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    const url = this.$route.hash.replace('#', '').replace('-', ' ');
    this.currentTab = this.listTab.indexOf(url);
    if (!this.$route.hash) {
      this.currentTab = 0;
    }
    this.$store.dispatch('setAgentId', null);
  },
  created() {
    if (this.isSsr()) {
      return;
    }
    window.addEventListener('scroll', this.listenScrollPosition);
  },
  destroyed() {
    window.removeEventListener('scroll', this.listenScrollPosition);
  },
  beforeDestroy() {
    clearTimeout(this.debounce.time);
  },
  methods: {
    /** @param currentTab {number} */
    tabClick(currentTab) {
      this.currentTab = currentTab;
      this.anchor = this.listTab[currentTab].replace(' ', '-');
      const action = this.listTab[currentTab].replace(/(^\w)|(\s\w)/g, m =>
        m.toLowerCase(),
      );
      const label = `/agen#${this.anchor}`;
      window.gtag('event', action, {
        event_category: 'Agen',
        event_label: label,
      });
      this.debounce.time = setTimeout(() => {
        window.scrollTo(0, 300);
      }, this.debounce.duration);
    },
    /** @param v {string} */
    openModal(v = '') {
      if (v === 'header') {
        window.scrollBy(0, 1);
      }
      this.showModal = true;
    },
    chooseForm() {
      this.openModal();
    },
    listenScrollPosition() {
      const el = document.querySelector('.tabbing');
      if (window.scrollY >= 479) {
        el.style.cssText = 'border: none;';
      } else {
        el.style.cssText = 'border-bottom: 1px solid #E0E0E0;';
      }
    },
    setErrorImage(event, addClass) {
      this.isErrorImage = true;
      setImageDefault(event, addClass);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
