/** @type {Array<AgentContent>} */
const DATA = [
  {
    text: 'Mendapatkan diskon dengan ketentuan sebagai berikut :',
    listText: [
      'Diskon penjualan 35% untuk jenis pengiriman Bosspack',
      'Diskon penjualan 30% untuk jenis pengiriman Regpack',
      'Diskon penjualan 25% untuk jenis pengiriman Jagopack',
      'Diskon penjualan 20% untuk jenis pengiriman Interpack',
      'Diskon penjualan sampai 25% untuk jenis pengiriman Bigpack',
      'Diskon penjualan 10% untuk jenis pengiriman Otopack',
      'Diskon penjualan sampai 25% untuk paket Marketplace (Tokopedia & Bukalapak)',
    ],
    customHTML: 'textBold',
    customHTMLDesc: '*Syarat & Ketentuan berlaku',
  },
  {
    text: 'Agen Lion Parcel diwajibkan mengantarkan Paket / Dokumen ke Sub Konsolidator / Konsolidator dan Shuttle Commuter / Shuttle Transit terdekat sesuai dengan jadwal yang telah ditetapkan.',
    listText: [],
  },
  {
    text: 'Radius/jarak satu Agen Lion Parcel dengan yang lainnya adalah 500 (lima ratus) meter. Disesuaikan dengan kondisi market, untuk gedung / pusat perbelanjaan (ITC atau sejenisnya), maka hanya diperbolehkan untuk 1 Agen Lion Parcel yang aktif di dalam 1 gedung.',
    listText: [],
  },
  {
    text: 'Lokasi pengajuan Agen Lion Parcel disarankan berada pada jalan utama atau jalan protokol.',
    listText: [],
  },
  {
    text: 'Melakukan pembayaran administrasi kepada Lion Parcel sebesar IDR 5.000.000. Dana secara otomatis akan teralokasikan 500.000 menjadi Modal Awal, dan sisanya akan dialokasikan dalam bentuk branding tools yang dengan detail berikut ini :',
    listText: [],
    customHTML: 'table',
  },
  {
    text: 'Wajib memasang branding material yang sudah disediakan secara lengkap dengan rapi dan di tempat yang terlihat dari segala sisi dan Agen Lion Parcel bersedia membayar Pajak untuk pemasangan branding.',
    listText: [],
  },
  {
    text: 'Bersedia membuka Agen Lion Parcel minimal 6 hari dalam seminggu, dengan jam buka-tutup yang jelas minimal 8 jam sehari.',
    listText: [],
  },
  {
    text: 'Bersedia memenuhi target penjualan yang sudah ditetapkan oleh Management.',
    listText: [],
  },
  {
    text: 'Proses persetujuan oleh Manajemen Lion Parcel selambat-lambatnya dalam kurun waktu 14 (empat belas) hari.',
    listText: [],
  },
  {
    text: 'Review Agen Lion Parcel akan dilakukan setelah 6 bulan dari aktivasi Agen Lion Parcel.',
    listText: [],
  },
  {
    text: 'Setiap Agen Lion Parcel wajib menandatangani Surat Penunjukkan Mitra (SPM).',
    listText: [],
  },
];

module.exports = { DATA };
