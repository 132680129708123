<template>
  <div
    class="tabbing"
    aria-label="tabs"
  >
    <div class="tabbing__wrapper">
      <div
        v-for="(tab, idx) in setListTab()"
        :id="setID(tab.label, tab.value)"
        :key="'tab-'+ idx"
        class="tabbing__card"
        @click="clickCurrentTab(tab.value, tab.label)"
      >
        <div
          :aria-rowindex="tab.value"
          class="tabbing__item"
          :class="{'tab-active': tab.value === currentTab}"
        >
          <span> {{ tab.label }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    listTab: {
      type: Array,
      required: true,
    },
    currentTab: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  methods: {
    /** @return {Array<{label: string, value: number}>} */
    setListTab() {
      return this.listTab.map((v, i) => ({
        label: v,
        value: i,
      }));
    },
    setID(label, val) {
      return `${label.split(' ').join('-').toLowerCase()}-${val}`;
    },
    clickCurrentTab(val, label) {
      this.$emit('tabClick', val);
      if (!this.isSsr()) {
        const el = document.querySelector(`#${this.setID(label, val)}`);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/style";

$tab-spacing: 36px;

.tab-active {
  color: $color-base !important;
  border-bottom-color: $color-base !important;
}

@keyframes border-animate {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

.tabbing {
  display: inline-block;
  overflow-y: hidden;
  overflow-x: auto;
  border-bottom: 1px solid $color-gray-pensive-5;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  z-index: -1;

  @include for-size(mobile) {
    display: flex;
    width: 100%;
  }

  @media (min-width: 600px) and (max-width: 680px) {
    width: 87vw;
  }

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  &__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;

    > div {
      margin-right: $tab-spacing;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__card {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
  }

  &__item {
    color: $color-gray-shade;
    border-bottom: 3px solid transparent;
    transition: all 0.4s ease;
    top: 1.3px;
    position: relative;
    z-index: 20;

    span {
      font-weight: 600;
      text-transform: capitalize;
      padding: 0 10px;
    }
  }
}
</style>
