<template>
  <article>
    <agent-information
      v-if="switchAgent('informasi') || !$route.hash"
      @chooseForm="chooseForm"
    />
    <term-of-agent
      v-if="switchAgent('syarat administrasi')"
      :contents="contentTerm.RULES"
      @openModal="$emit('openModal')"
    />
    <term-of-agent
      v-if="switchAgent('syarat operasional')"
      :contents="contentOperational.OPERATIONAL"
    />
    <general-requirements v-if="switchAgent('ketentuan umum')" />
  </article>
</template>
<script>
import AgentInformation from './Information';
import TermOfAgent from '../components/Term';
import contentTerm from '../app/constants/administration';
import contentOperational from '../app/constants/operational';
import GeneralRequirements from './GeneralRequirements';

export default {
  name: 'AgentSection',
  components: {
    AgentInformation,
    GeneralRequirements,
    TermOfAgent,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contentTerm,
      contentOperational,
    };
  },
  methods: {
    /**
     *
     * @param name: {string}
     * @return {boolean}
     */
    switchAgent(name) {
      return this.label === name;
    },
    chooseForm() {
      this.$emit('chooseForm');
    },
  },
};

</script>
