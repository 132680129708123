/** @type {Array<AgentContent>} */
const OPERATIONAL = [
  {
    text: 'Menyediakan peralatan pendukung, seperti :',
    listText: [
      'Timbangan Digital minimal 60kg yang dikalibrasi',
      'Menyediakan minimal 1 PC Desktop (Komputer) atau Laptop',
      'Menyediakan Printer',
      'Menyediakan Koneksi Internet',
      'Memiliki fasilitas komunikasi telepon',
      'Alat ukur atau meteran',
      'Menyediakan CCTV di Lokasi penerimaan barang',
    ],
  },
  {
    text: 'Memiliki sumber daya manusia untuk mendukung kinerja Agen seperti:',
    listText: [
      'Menyediakan staff admin',
      'Menyediakan staff admin dengan menyerahkan fotokopi KTP',
      'Memiliki minimum 1 (Satu) armada motor atau mobil untuk keperluan operasional Agen Lion Parcel dengan menyerahkan fotokopi KTP dan SIM serta nomer Handphone masing-masing staff',
      'Melampirkan fotokopi STNK masing-masing kendaraan bermotor',
    ],
  },
];

module.exports = { OPERATIONAL };
