<template>
  <div class="base-agent-content base-agent-term">
    <card-content
      v-for="(li, idx) in contents"
      :key="'li-'+idx"
      :list-text="li.listText"
      :text="li.text"
      :num="(idx+1) + ''"
      @openModal="$emit('openModal')"
    />
  </div>
</template>

<script>
import CardContent from '../components/CardContent';

export default {
  name: 'TermOfAgent',
  components: { CardContent },
  props: {
    contents: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/style.scss";
@import "../child/style";
</style>
