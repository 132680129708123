/** @type {Array<AgentContent>} */
const RULES = [
  {
    text: 'Berbentuk Badan Usaha minimal CV dapat juga perorangan.',
    listText: [],
  },
  {
    text: '<div id="containEvent">Mengajukan pendaftaran dengan mengisi <span id="openAgenFormModal" style="cursor: pointer; color: #B82025;">Form Aplikasi Agen Lion Parcel.</span></div>',
    listText: [],
  },
  {
    text: 'Pengajuan berbentuk Badan Usaha melampirkan :',
    listText: [
      'Fotokopi Akte Pendirian/Perubahan Perusahaan',
      'Fotokopi SIUP (Surat Izin Usaha Pendirian Perusahaan)',
      'Fotokopi TDP (Tanda Daftar Perusahaan)/Surat Keterangan Domisi',
      'Fotokopi NPWP (Nomor Pokok Wajib Pajak) Perusahaan/Surat Pengukuhan KTP/Surat Keterangan Terdaftar Dirjen Pajak',
      'Fotokopi KTP Pemilik/Direktur',
      'Memiliki tempat usaha sesuai dengan form pendaftaran, paling tidak minimum 2 (dua) tahun untuk tempat usaha yang masih sewa',
      'Melampirkan titik koordinat/foto lokasi',
      'Fotokopi halaman pertama buku rekening',
    ],
  },
  {
    text: 'Pengajuan berbentuk perorangan, melampirkan :',
    listText: [
      'Fotokopi KTP',
      'Fotokopi NPWP',
      'Fotokopi KK',
      'Melampirkan titik koordinat/foto lokasi',
      'Melampirkan foto halaman depan buku tabungan (tertera nama & nomor rekening)',
    ],
  },
];

module.exports = { RULES };
