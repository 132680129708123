<template>
  <div class="base-agent-content">
    <section class="agent-motto">
      <div class="agent-motto__desc">
        <div>
          <span class="agent-motto__title">
            Lebih Dari 7.000 Agen Lion Parcel dari Seluruh Indonesia Telah Bergabung
          </span>
        </div>
        <div class="call-agent">
          <span>
            <img
              :src="`/images/agent/icon_telphone.svg`"
              alt="call center lionparcel.svg"
            >
            <span class="call-agent__field">{{ telephone.field }}</span>
            <a
              :href="telephone.number"
              class="call-agent__title"
            >{{ telephone.title }}</a>
          </span>
        </div>
      </div>
      <div class="agent-motto__img">
        <image-lazy
          :src="getMapImage"
          alt="agen-lionparcel.png"
        />
      </div>
    </section>
    <section>
      <profit-and-registration-content
        :contents-list="CONTENT.PROFIT"
        :title="'Keuntungan Menjadi <span>Agen Lion Parcel</span>'"
        :with-linear-gradient-background="true"
      />
    </section>
    <section>
      <profit-and-registration-content
        :contents-list="CONTENT.REG"
        title="4 Langkah Mudah, Menjadi Agen Lion Parcel"
        :ill-img="`/images/agent/ill_agen.svg`"
      >
        <template>
          <div>
            Mengisi <a
              id="form-agent"
              @click="popUp()"
            >formulir</a> pendaftaran agen
            perorangan/perusahaan &
            menunggu respon persetujuan maksimal 14 hari kerja.
          </div>
        </template>
      </profit-and-registration-content>
    </section>
    <section class="agent-story">
      <div class="agent-story__title">
        Cerita dari Agen Lion Parcel
      </div>
      <div id="testimony-agent">
        <div>
          <card
            v-for="(client, idx) in CONTENT.TESTI"
            :key="'t-'+ idx"
            :title="client.title"
            :desc="client.desc"
            :name="client.name"
            :src="client.src"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ProfitAndRegistrationContent from '@/components/new-profit-and-registration/ProfitAndRegistrationContent';
import Card from '../components/CardTestimoni';
import CONTENT from '../app/constants/information';
import mixinMobile from '@/misc/mixinMobile';
import ImageLazy from '@/components/ImageLazy/index.vue';

export default {
  name: 'AgentInformation',
  components: { Card, ProfitAndRegistrationContent, ImageLazy },
  mixins: [mixinMobile],
  data() {
    return {
      CONTENT,
      telephone: {
        number: 'tel:+6281119561000',
        title: '0811-1956-1000',
        field: 'Mitra Care NPOS',
      },
    };
  },
  computed: {
    getMapImage() {
      return this.isMobileSize ? '/images/agent/informasi_map_sm.svg' : '/images/agent/informasi_map.svg';
    },
  },
  methods: {
    popUp() {
      this.$emit('chooseForm');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/style";
@import "src/assets/css/flexbox";
@import "src/assets/css/calculate";
@import "./style.scss";

.agent-motto {
  display: flex;
  align-items: center;
  padding: 0 $padding-x;
  margin-bottom: 3em;

  @media (max-width: 699px) {
    flex-direction: column-reverse;
    align-items: normal;
    margin-bottom: unset;
    padding: 0 ($padding-x - 3);
    > div {
      margin-bottom: 2em;
    }
  }

  &__title {
    color: $color-base;
    @include cal-size(font-size, 22, 46);
    font-weight: 700;
    @include cal-size(line-height, 30, 70);
  }

  &__desc {
    flex: 1 49%;
  }

  &__img {
    flex: 1 30%;
    display: flex;
    justify-content: flex-end;

    > div:first-child {
      min-height: 200px;
      width:100%;

      @media (min-width: 990px) {
        width: 46%;
      }

      ::v-deep img {
        width: 100%;
        display: block;
      }
    }

    @include for-size(mobile) {
      margin-top: 2.5em;
    }

    @media (max-width: 699px) {
      justify-content: center;
    }

    div:last-of-type {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.call-agent {
  margin-top: 2em;

  > span {
    width: max-content;
    display: inline-flex;
  }

  @media (max-width: 300px) {
    span {
      display: block;
    }
    > span {
      margin-top: 1em;
    }
  }


  span a {
    color: $color-base-text;
    margin-right: .30em;
    font-weight: 700;

    :link, :visited, :hover, :active {
      text-decoration: none;
    }
  }

  span img {
    position: relative;
    margin-right: .5em;
  }

  &__field {
    color: $color-base-text;

    &:first-of-type {
      margin-right: 0.3em;
    }
  }

  &__title {
    font-size: 16px;
  }
}

#testimony-agent {
  overflow-x: auto;
  text-align: center;

  div {
    display: inline-flex;

    > div:not(:last-of-type) {
      margin-right: 1.5%;
    }
  }

  @media (max-width: 699px) {
    > div {
      margin: 0 2%;
    }
  }
}

.agent-story {
  padding: 20px 0;
  background: linear-gradient(to top, #ffffff 50%, #fff7f7 100%, #ffffff);

  @include for-size(mobile) {
    background: linear-gradient(0deg, #fff 0%, #fff7f7 100%, #fff7f7 50%);
  }

  &__title {
    @include cal-size(font-size, 18, 26);
    @include cal-size(line-height, 28, 36);
    padding: 0 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2.5em;
  }
}

#form-agent {
  color: $color-base;
  text-decoration: underline;
}

@include hide-scroll-overflow();
</style>
