<template>
  <modal
    id="modal-agent"
    @close="$emit('close')"
  >
    <template>
      <span id="title-modal-agent"> Daftar Sebagai: </span>
      <div id="content-modal-agent">
        <div
          v-for="(dataInput, idx) of listChoose"
          :key="'input-'+idx"
          class="choose-agent"
        >
          <div class="choose-agent__input">
            <input
              :id="dataInput.name"
              type="radio"
              name="choose-form"
              :value="dataInput.value"
              class="choose-form"
              checked
            >
            <div>
              <span
                class="dot"
                :class="{'dot__choose': dataInput.checked}"
                @click="chooseMe(idx, dataInput.value)"
              >
                <span
                  class="inner-dot"
                  :class="{'inner-dot__choose': dataInput.checked}"
                />
              </span>
            </div>
          </div>
          <div>
            <label
              :for="dataInput.name"
              @click="chooseMe(idx, dataInput.value)"
            >
              <span>{{ dataInput.title }}</span>
              <span>{{ dataInput.desc }}</span>
            </label>
          </div>
          <img
            :src="dataInput.img"
            alt="logo-form.png"
          >
        </div>
        <the-button
          id="content-modal-agent__btn"
          text="Isi Formulir"
          @click="$router.push(choose)"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import TheButton from '@/components/new-button/Button';
import Modal from '@/components/new-modal/Modal';

export default {
  name: 'ModalAgent',
  components: { Modal, TheButton },
  data() {
    return {
      choose: '/agen/perorangan-app',
      listChoose: [
        {
          name: 'form-perorangan',
          value: '/agen/perorangan-app',
          checked: true,
          img: '/images/agent/personal_business.svg',
          title: 'Perorangan',
          desc: 'Formulir Pendaftaran Perorangan',
        },
        {
          name: 'form-perusahaan',
          value: '/agen/perusahaan-app',
          checked: false,
          img: '/images/agent/company_business.svg',
          title: 'Perusahaan',
          desc: 'Formulir Pendaftaran Perusahaan',
        },
      ],
    };
  },
  methods: {
    /**
     * @param idx {number}
     * @param pick {string}
     */
    chooseMe(idx, pick) {
      this.choose = pick;
      this.listChoose.forEach((val, i) => val.checked = idx === i);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/style.scss";

#title-modal-agent {
  font-weight: 600;
  color: $color-base-text;
}

#content-modal-agent {
  display: flex;
  flex-direction: column;

  &__btn {
    width: fit-content;
    display: flex;
    margin: 2.5em auto 1.25em auto;
  }
}

#modal-agent {
  ::v-deep .modal-lp__content {
    width: 470px !important;
  }

  ::v-deep .modal-lp__content__close img {
    margin-top: 1.05em;
  }
}

.choose-agent {
  margin-top: 1em;
  padding: 11px 21px;
  border-radius: 5px;
  border: 1.5px solid $color-gray-pensive;
  position: relative;
  display: flex;
  align-items: center;

  @include for-size(mobile) {
    padding: 9px 11px;
  }

  div:last-of-type label {
    cursor: pointer;
    display: flex;
    flex-direction: column;

    span:first-of-type {
      font-weight: 600;
      font-size: 11pt;
    }

    span:last-of-type {
      z-index: 2;
      font-size: 10pt;
    }
  }

  img {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__input {
    position: relative;
    width: 10%;
  }
}

input[name="choose-form"].choose-form {
  visibility: hidden;
}

.dot {
  bottom: 9px;
  height: 16px;
  width: 16px;
  background-color: white;
  border-radius: 50%;
  position: relative;
  padding: 4px;
  display: inline-block;
  border: 1.5px solid $color-white-dark;

  &__choose {
    border-color: $color-base;
  }
}

.inner-dot {
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: white;
  border-radius: 50%;
  top: 12%;
  left: 11.7%;

  &__choose {
    background-color: $color-base;
  }
}

</style>
