/** @type {Array<AgentInfo>} */
const PROFIT = [
  {
    title: 'Keuntungan Diskon yang Besar',
    desc:
            'Diskon penjualan yang menarik hingga 35% dari pendapatan tiap bulannya.',
    img: 'ics_f_money_alt.svg',
  },
  {
    title: 'Syarat Mudah, Tanpa Biaya Royalti',
    desc:
            'Sistem kerjasama yang mudah, tidak ribet, tidak perlu modal besar dan tanpa biaya royalti.',
    img: 'agent/ics_f_love.svg',
  },
  {
    title: 'Harga yang Kompetitif',
    desc:
            'Biaya pengiriman yang murah, hemat dan bersaing dengan kompetitor lainnya.',
    img: 'agent/ics_f_payment.svg',
  },
  {
    title: 'Jangkauan Area yang Luas',
    desc:
            'Menjangkau ke seluruh wilayah Indonesia serta menggunakan armada pesawat Lion Air Group.',
    img: 'agent/ics_f_maps.svg',
  },
  {
    title: 'Sudah Tersedia di Marketplace',
    desc:
            'Lion Parcel telah bekerjasama dengan Marketplace seperti Tokopedia dan Bukalapak.',
    img: 'agent/ics_f_storeFavorite.svg',
  },
  {
    title: 'Gratis Pelatihan & Gabung Komunitas Aktif',
    desc:
            'Dapatkan pelatihan bisnis dan gabung ke komunitas Agen Lion Parcel (Mitra POS) di @lioakademi.',
    img: 'agent/ics_f_helpdesk.svg',
  },
];

/** @type {Array<AgentInfo>} */
const REG = [
  {
    title: 'Pengajuan Formulir',
    desc: '',
    number: '1',
    hasClickEvent: true,
  },
  {
    title: 'Persetujuan & Pembayaran Administrasi',
    desc:
            'Lakukan pembayaran untuk administrasi & Anda akan mendapatkan Partner ID serta kelengkapan marketing. ',
    number: '2',
  },
  {
    title: 'Mengikuti Program Pelatihan',
    desc:
            'Anda akan menerima pelatihan bisnis & sistem Genesis serta bersedia memasang materi promosi di lokasi Agen.',
    number: '3',
  },
  {
    title: 'Agen Lion Parcel Siap Usaha',
    desc: 'Mulai lakukan penjualan & dapatkan komisi hingga 35% dengan sistem yang terintegrasi tinggi dan terpercaya.',
    number: '4',
  },
];

/** @type {Array<AgentInfo>} */
const TESTI = [
  {
    src: 'agent/agent_story_1.webp',
    title: 'POS Laksda M Natsir',
    name: 'Ika Rachma Nataria',
    desc: '“Bisnis Agen Lion Parcel ini  sangat membantu kami dalam usaha bisnis menengah ke bawah untuk memiliki penghasilan menengah ke atas.”',
  },
  {
    src: 'agent/agent_story_2.webp',
    title: 'POS Kristina Wulandari',
    name: 'Kristina Wulandari',
    desc: '“Usaha ini mendapatkan keuntungan yang besar daripada pengeluaran yang harus kami bayarkan ketika belum membuka bisnis ini.”',
  },
  {
    src: 'agent/agent_story_3.webp',
    title: 'POS Ambon Matras',
    name: 'Joko',
    desc: '“Bergabung Agen Lion Parcel membantu sekali UKM untuk mengembangkan ekonomi masyarakat yang ingin maju bersama.”',
  },
  {
    src: 'agent/agent_story_4.webp',
    title: 'POS Subiyanto',
    name: 'Subiyanto',
    desc: '“Selain usaha kami terbantu, kamu juga membuka lapangan pekerjaan bagi karyawan kami yang selama pandemi sulit dapat pekerjaan.\n'
            + '”',
  },
];

module.exports = { REG, PROFIT, TESTI };
