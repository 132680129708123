<template>
  <div class="base-agent-content base-agent-term">
    <div
      v-for="(li, idx) in content.DATA"
      :key="'li-'+idx"
    >
      <card-content
        v-if="!li.customHTML"
        :list-text="li.listText"
        :text="li.text"
        :num="(idx+1) + ''"
      />
      <card-content
        v-if="li.customHTML === 'textBold'"
        :list-text="li.listText"
        :text="li.text"
        :num="(idx+1) + ''"
      >
        <template>
          <span style="font-weight: 600; margin-top: .75em; padding-left: .45em"> {{ li.customHTMLDesc }}</span>
        </template>
      </card-content>
      <card-content
        v-if="li.customHTML === 'table'"
        :list-text="li.listText"
        :text="li.text"
        :num="(idx+1) + ''"
      >
        <template>
          <div id="table-content">
            <div>
              <table
                aria-label="Tabel List Kebutuhan Umum Lion Parcel"
                class="agent-goods"
              >
                <tr>
                  <th
                    v-for="(t, idx) in headers"
                    :key="'th-'+idx"
                  >
                    {{ t }}
                  </th>
                </tr>
                <tr
                  v-for="(d, idx) in firstContentTable"
                  :key="'tr-first-'+ idx"
                >
                  <td>{{ idx + 1 }}</td>
                  <td>{{ d.name }}</td>
                  <td>{{ d.quantity }}</td>
                </tr>
              </table>
            </div>
            <div>
              <table
                aria-label="Tabel List Kebutuhan Umum Lion Parcel"
                class="agent-goods"
              >
                <tr>
                  <th
                    v-for="(t, idx) in headers"
                    :key="'th-'+idx"
                  >
                    {{ t }}
                  </th>
                </tr>
                <tr
                  v-for="(d, idx) in secondContentTable"
                  :key="'tr-second-'+ idx"
                >
                  <td>{{ (idx + 1) + firstContentTable.length }}</td>
                  <td v-html="d.name" />
                  <td>{{ d.quantity }}</td>
                </tr>
              </table>
            </div>
          </div>
        </template>
      </card-content>
    </div>
  </div>
</template>

<script>
import CardContent from '../components/CardContent';
import content from '../app/constants/requirements';

export default {
  name: 'GeneralRequirementsAgent',
  components: { CardContent },
  data() {
    return {
      content,
      headers: ['No', 'Nama Produk', 'Quantity'],
      firstContentTable: [
        {
          name: 'Spanduk',
          quantity: 1,
        },
        {
          name: 'Standing Banner',
          quantity: 1,
        },
        {
          name: 'Neon Box',
          quantity: 1,
        },
        {
          name: 'Set Poster Lion Parcel',
          quantity: 1,
        },
        {
          name: "Brosur",
          quantity: 1,
        },
      ],
      secondContentTable: [
        {
          name: "Jaket",
          quantity: 1,
        },
        {
          name: "Kaos Polo",
          quantity: 2,
        },
        {
          name: "Tas Kurir",
          quantity: 1,
        },
        {
          name: "Helm",
          quantity: 1,
        },
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";

#table-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2em;

  > div {
    margin-right: 2%;
  }
}

.agent-goods {
  border-collapse: collapse;
  width: 100%;

  td:first-of-type, td:last-of-type {
    text-align: center;
  }

  td:first-of-type {
    border-left: 1px solid #F3F3F3;
  }

  td:nth-child(2) {
    width: 190px;
  }

  tr td {
    color: $color-base-text;
    padding: .35em 2em .35em 1em;
    border-bottom: 1px solid #E0E0E0;
    font-size: 10pt;
  }

  tr td:last-of-type {
    border-right: 1px solid #F3F3F3;
  }

  th {
    color: $color-base-text;
    font-weight: 600;
    background-color: #F5F6F7;
    border: 1px solid #F3F3F3;
    padding: .45em 2em .45em 1em;
  }

  @media (max-width: 376px) {
    th {
      padding: .45em .35em .45em .35em;
    }

    th:first-of-type {
      padding-left: 8px;
    }
  }
}

@include for-size(mobile) {
  #table-content {
    padding: 2em 0 0 0;

    > div {
      margin-right: 0;
    }
  }
}

@media (max-width: 971px) {
  #table-content {
    padding: 2em 0 0 0;
  }

  .agent-goods:first-of-type {
    margin-bottom: 5%;
  }
}
</style>
