<template>
  <div class="base-card-content">
    <div>
      <div class="base-card-content__num">
        {{ num }}
      </div>
    </div>
    <div>
      <div
        v-if="typeof text === 'string'"
        class="base-card-content__text"
        @click="handleClickSpan"
        v-html="text"
      />
      <div
        v-if="Array.isArray(listText) && listText.length > 0"
        class="base-card-content__list-text"
      >
        <ul>
          <li
            v-for="(t, idx) in listText"
            :key="'text-'+idx"
          >
            {{ t }}
          </li>
        </ul>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "CardAgentContent",
  props: {
    num: {
      type: String,
      required: true,
      default: "-",
    },
    text: {
      type: String,
      required: true,
    },
    listText: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleClickSpan(e) {
      if(e.target.id === 'openAgenFormModal') this.$emit("openModal")
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/style";
@import "src/assets/css/calculate";

$m: 0.85em;

.base-card-content {
  display: flex;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: $m + 0.65;

  &__num {
    background-color: $color-base;
    color: white;
    font-weight: 600;
    font-size: 12pt;
    line-height: 20pt;
    width: 1.65em;
    height: 1.65em;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-content: center;

    @include for-size(mobile) {
      font-size: 10pt;
    }
  }

  div:last-of-type {
    display: flex;
    flex-direction: column;
  }

  &__text {
    font-weight: 600;
    color: $color-base-text;
    margin-left: .75em;
  }

  &__list-text {
    margin-left: .80em;
  }
}

ul {
  padding: 0 1.85em;
  font-size: 12pt;
  margin-top: $m;

  > li {
    list-style-type: lower-alpha;
  }

  > li:not(:last-of-type) {
    margin-bottom: $m;
  }
}
</style>
